import { calcTimeDelta } from "react-countdown";
import { db } from "../data/base";
import { format } from 'date-fns';

export const formatDate = (dateString) => {
  const [month, year] = dateString?.split("/");


  const date = new Date(`${year}-${month}-01`);
  const formattedDate = date.toLocaleString("en-US", {
    month: "short",
    year: "numeric",
  });
  return formattedDate;
};

function getDateParts(timestamp) {
  if (!timestamp) return [null, null, null];

  const date = new Date(timestamp.seconds * 1000);

  const dayCheck = format(date, 'dd');
  const monthCheck = format(date, 'MM');
  const yearCheck = format(date, 'yyyy');

  return [dayCheck, monthCheck, yearCheck];
}

export const generateMissingData = (startDate, endDate, data, isBandwidth) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const missingData = [];

  while (start < end) {
    const year = start.getFullYear();
    const month = start.getMonth() + 1;

    if (!data.some((item) => item.year === year && item.month === month)) {
      missingData.push({ year, month, bandwidth: null });
    }

    start.setMonth(start.getMonth() + 1);
  }

  return [...data, ...missingData].sort((a, b) => {
    if (a.year === b.year) {
      return a.month - b.month;
    } else {
      return a.year - b.year;
    }
  });
};

export const getData = async ({
  startDate,
  endDate,
  setCallDetails,
  setLineChartData,
  setBarChartData,
  selectedBrandId,
  bandwidthType,
  brandList,
  setBandwidthDetail,
}) => {

  const snapshot = await db.collection("Contatori_Brand").get();
  let data = [];

  var brandListoConsumprion = [];
  brandList.map((brand) => {

    const [dayCheck, monthCheck, yearCheck] = brand?.endDate?.split('/');
    const dayIntCheck = parseInt(dayCheck, 10);
    const monthIntCheck = parseInt(monthCheck, 10) - 1;
    const yearIntCheck = parseInt(yearCheck, 10);
    let checkData = new Date(yearIntCheck, monthIntCheck, dayIntCheck);
    const dataOdierna = new Date();

    if (dataOdierna < checkData) {
      // console.log("VALIDE BRAND", brand)
      brandListoConsumprion.push(brand)

      // console.log("BRAND LIST", brandListoConsumprion)
    }
  })


  // if (selectedBrandId && selectedBrandId !== "all") {
  //   const dataArr = snapshot.docs.filter((doc) => {
  //     return doc.id === selectedBrandId;
  //   });
  //   data = dataArr.map((item) => item.data());
  // } else {
  const dataArr = snapshot.docs.filter((doc) => brandListoConsumprion.some((data) => data.data_consumption === doc.id));
  data = dataArr.map((item) => item.data());

  // }
  // console.log("Firebase Data", data)
  const bandwidthData = data
    .map((item) => {
      return [
        ...(bandwidthType === "bandwidth_3dviewer" || bandwidthType === "all"
          ? (item && item.bandwidth_3dviewer) ? item.bandwidth_3dviewer : []
          : []),
        ...(bandwidthType === "bandwidth_array" || bandwidthType === "all"
          ? (item && item.bandwidth_array) ? item.bandwidth_array : []
          : []),
        ...(bandwidthType === "bandwidth_webVto" || bandwidthType === "all"
          ? (item && item.bandwidth_webVto) ? item.bandwidth_webVto : []
          : []),
        ...(bandwidthType === "storage_data" || bandwidthType === "all"
          ? (item && item.storage_data) ? item.storage_data : []
          : []),
      ];
    })
    .flat()
    .filter((item) => {
      const year = Number(item && item.year);
      const month = Number(item && item.month);
      const date = new Date(year, month - 1, 1);

      // console.log("ITEM", item , date)

      return (
        (startDate === null || date >= startDate) &&
        (endDate === null || date <= endDate)
      );
    })
    .reduce((acc, item) => {
      const existingItem = acc.find(
        (accItem) => Number(accItem.year) === Number(item?.year) && Number(accItem.month) === Number(item?.month)
      );
      // console.log("ITEM Existing", item ,existingItem,acc )

      if (existingItem) {
        existingItem.bandwidth += item?.bandwidth_month;
      } else {
        acc.push({
          year: Number(item?.year),
          month: Number(item?.month),
          bandwidth: item?.bandwidth_month,
        });
      }
      // console.log("Filtered Data", acc)
      return acc;
    }, []);

  // console.log("Load Data", data)
  const callsData = data
    .map((item) => {
      return [
        ...(bandwidthType === "bandwidth_3dviewer" || bandwidthType === "all"
          ? (item && item.bandwidth_3dviewer) ? item.bandwidth_3dviewer : []
          : []),
        ...(bandwidthType === "bandwidth_array" || bandwidthType === "all"
          ? (item && item.bandwidth_array) ? item.bandwidth_array : []
          : []),
        ...(bandwidthType === "bandwidth_webVto" || bandwidthType === "all"
          ? (item && item.bandwidth_webVto) ? item.bandwidth_webVto : []
          : []),
        ...(bandwidthType === "storage_data" || bandwidthType === "all"
          ? (item && item.storage_data) ? item.storage_data : []
          : []),
      ];
    })
    .flat()
    .filter((item) => {
      const year = Number(item && item.year);
      const month = Number(item && item.month);
      const date = new Date(year, month - 1, 1);
      return (
        (startDate === null || date >= startDate) &&
        (endDate === null || date <= endDate)
      );
    })
    .reduce((acc, item) => {
      const existingItem = acc.find(
        (accItem) =>
          Number(accItem.year) === Number(item?.year) &&
          Number(accItem.month) === Number(item?.month)
      );
      // console.log("ITEM",existingItem)
      if (existingItem) {
        existingItem.calls += item?.calls_number;
      } else {
        acc.push({
          year: Number(item?.year),
          month: Number(item?.month),
          calls: item && item.calls_number ? item.calls_number : 0,
          calls_number: item && item.calls_number ? item.calls_number : 0,
        });
      }
      // console.log("CALL DATA", acc)

      return acc;
    }, []);
  const totalCalls = callsData.reduce(
    (acc, item) => acc + (item && item.calls ? item.calls : 0),
    0
  );

  const startingDate =
    callsData.length > 0
      ? `${Number(callsData[0]?.month)}/${Number(callsData[0]?.year)}`
      : "";

  const endingDate =
    callsData.length > 0
      ? `${callsData[callsData.length - 1]?.month}/${callsData[callsData.length - 1]?.year
      }`
      : "";
  // console.log("TOTAL CALLS", totalCalls)
  setCallDetails({
    totalCalls: totalCalls,
    dateRange: `${(startingDate)} - ${(endingDate)}`,
  });



  function sortByYearAndMonth(array) {
    return array.sort((a, b) => {
      // prima ordina per anno
      if (a.year < b.year) return -1;
      if (a.year > b.year) return 1;

      // poi ordina per mese
      if (a.month < b.month) return -1;
      if (a.month > b.month) return 1;

      return 0;
    });
  }

  function calcolaTotalBandWidth(bandwidthData) {
    var dataset = []
    var counter = 0
    var orderBandwidth = sortByYearAndMonth(bandwidthData)
    orderBandwidth.map((item) => {

      dataset.push({
        year: item.year,
        month: item.month,
        bandwidth: counter += item.bandwidth
      });

    })
    // console.log("Dati ordinati", dataset)
    return [dataset, counter]
  }

  var [dataSet, totalBand] = calcolaTotalBandWidth(bandwidthData);

  setBandwidthDetail({
    totalBandWidth: (totalBand / 1024).toFixed(2),
    dataRange: `${(startingDate)} - ${(endingDate)}`,
  })
  // console.log(dataSet)
  // console.log("Banda",bandwidthData);

  const lineChartData = {
    labels: generateMissingData(startDate, endDate, bandwidthData).map(
      (item) => `${Number(item && item.month) ? item.month : ""}/${Number(item && item.year) || ""}`
    ),
    datasets: [
      {
        label: "Monthly Bandwidth (GB)",
        data: generateMissingData(startDate, endDate, bandwidthData).map(
          (item) => {
            return (item && item.bandwidth ? item.bandwidth / 1024.0 : 0).toFixed(2);
          }
        ),
        borderColor: "#41b1ac",
        backgroundColor: "#41b1ac60",
        fill: true,
        tension: 0,
      },
      {
        label: "Total BandWidth (GB)",
        data: generateMissingData(startDate, endDate, dataSet).map((item) => {
          return (item && item.bandwidth ? item.bandwidth / 1024.0 : 0).toFixed(2);
        }),
        borderColor: '#ff2464',
        backgroundColor: '#ff246460',
        fill: true,
        tension: 0
      },
      // {
      //   label: "Free Limit Month",
      //   data: generateMissingData(startDate, endDate, bandwidthData).map(
      //     (item) => 25
      //   ),
      //   borderColor: "#000",
      //   fill: false,
      //   tension: 0,
      // },
    ],
  };
  setLineChartData(lineChartData);

  // Update bar chart data
  const barChartData = {
    labels: generateMissingData(startDate, endDate, callsData).map(
      (item) => `${Number(item && item.month) ? item.month : ""}/${Number(item && item.year) || ""}`
    ),

    datasets: [
      {
        label: "Calls",
        data: generateMissingData(startDate, endDate, callsData).map(
          (item) => item?.calls
        ),
        backgroundColor: "#241F45",
      },
    ],
  };
  setBarChartData(barChartData);
};

export const getSubscriptionData = async ({
  startDate,
  endDate,
  bandwidthType,
  brandList,
  subStartDate,
  subscriptions
}) => {

  console.log("brand", brandList);
  const snapshot = await db.collection("Contatori_Brand").get();
  let data = [];
  const milliseconds = subStartDate.seconds * 1000 + Math.round(subStartDate.nanoseconds / 1000000);
  const subDate = new Date(milliseconds);

  var brandListoConsumption = [];
  const [dayCheck, monthCheck, yearCheck] = getDateParts(subscriptions?.endDate);
  const dayIntCheck = parseInt(dayCheck, 10);
  const monthIntCheck = parseInt(monthCheck, 10) - 1;
  const yearIntCheck = parseInt(yearCheck, 10);
  let checkData = new Date(yearIntCheck, monthIntCheck, dayIntCheck);
  const dataOdierna = new Date();

  // console.log("subsc", subscriptions, checkData, dataOdierna);

  let dataArr = null
  const validConsumption = []

  if (dataOdierna < checkData) {
    // console.log("valide data", subscriptions?.consumption_list.consumptionRef)
    // console.log("VALIDE BRAND", brand)
    dataArr = snapshot.docs.map((doc) => {
      // console.log(doc.data());
      if (subscriptions?.consumption_list.consumptionRef === doc.id) {
        // console.log("valid consum")
        validConsumption.push(doc.data())
      }
    }
    );
    // console.log("consumption", validConsumption)
  }


  // if (selectedBrandId && selectedBrandId !== "all") {
  //   const dataArr = snapshot.docs.filter((doc) => {
  //     return doc.id === selectedBrandId;
  //   });
  //   data = dataArr.map((item) => item.data());
  // } else {

  data = validConsumption;
  // console.log("Firebase Data", data)
  const bandwidthData = data
    .map((item) => {
      return [
        ...(bandwidthType === "bandwidth_3dviewer" || bandwidthType === "all"
          ? (item && item.bandwidth_3dviewer) ? item.bandwidth_3dviewer : []
          : []),
        ...(bandwidthType === "bandwidth_array" || bandwidthType === "all"
          ? (item && item.bandwidth_array) ? item.bandwidth_array : []
          : []),
        ...(bandwidthType === "bandwidth_webVto" || bandwidthType === "all"
          ? (item && item.bandwidth_webVto) ? item.bandwidth_webVto : []
          : []),
        ...(bandwidthType === "storage_data" || bandwidthType === "all"
          ? (item && item.storage_data) ? item.storage_data : []
          : []),
      ];
    })
    .flat()
    .filter((item) => {
      const year = Number(item && item.year);
      const month = Number(item && item.month);
      const date = new Date(year, month - 1, 1);
      // console.log("ITEM", item , date)

      return (
        (subDate <= date) &&
        (startDate === null || date >= startDate) &&
        (endDate === null || date <= endDate)
      );
    })
    .reduce((acc, item) => {

      const existingItem = acc.find(
        (accItem) => Number(accItem.year) === Number(item?.year) && Number(accItem.month) === Number(item?.month)
      );
      // console.log("ITEM Existing", item ,existingItem,acc )

      if (existingItem) {

        existingItem.bandwidth += item?.bandwidth_month ? item?.bandwidth_month : getDataConsumptionUnitValue(item?.consumption, item?.month, item?.year);

      } else {

        acc.push({
          year: Number(item?.year),
          month: Number(item?.month),
          bandwidth: item?.bandwidth_month ? item?.bandwidth_month : getDataConsumptionUnitValue(item?.consumption, item?.month, item?.year)
        });

      }
      // console.log("Filtered Data", acc)
      return acc;
    }, []);

  // console.log("bandwidthData", bandwidthData, data)

  // // console.log("Load Data", data)
  const callsData = data
    .map((item) => {
      return [
        ...(bandwidthType === "bandwidth_3dviewer" || bandwidthType === "all"
          ? (item && item.bandwidth_3dviewer) ? item.bandwidth_3dviewer : []
          : []),
        ...(bandwidthType === "bandwidth_array" || bandwidthType === "all"
          ? (item && item.bandwidth_array) ? item.bandwidth_array : []
          : []),
        ...(bandwidthType === "bandwidth_webVto" || bandwidthType === "all"
          ? (item && item.bandwidth_webVto) ? item.bandwidth_webVto : []
          : []),
        ...(bandwidthType === "storage_data" || bandwidthType === "all"
          ? (item && item.storage_data) ? item.storage_data : []
          : []),
      ];
    })
    .flat()
    .filter((item) => {
      const year = Number(item && item.year);
      const month = Number(item && item.month);
      const date = new Date(year, month - 1, 1);
      return (
        (subDate <= date) &&
        (startDate === null || date >= startDate) &&
        (endDate === null || date <= endDate)
      );
    })

    .reduce((acc, item) => {
      const existingItem = acc.find(
        (accItem) =>
          Number(accItem.year) === Number(item?.year) &&
          Number(accItem.month) === Number(item?.month)
      );
      // console.log("ITEM", existingItem)
      if (existingItem) {
        if (item?.calls_number != null && item?.models_number == null) {
          existingItem.calls += item?.calls_number;
        } else {
          existingItem.calls += item?.models_number;
        }
      } else {

        if (item?.calls_number != null && item?.models_number == null) {

          acc.push({
            year: Number(item?.year),
            month: Number(item?.month),
            calls: item && item.calls_number ? item.calls_number : 0,
            calls_number: item && item.calls_number ? item.calls_number : 0,
          });
        } else {
          acc.push({
            year: Number(item?.year),
            month: Number(item?.month),
            calls: item && item.models_number ? item.models_number : 0,
            calls_number: item && item.models_number ? item.models_number : 0,
          });

        }
      }
      // console.log("CALL DATA",acc)

      return acc;
    }, []);

  const totalCalls = callsData.reduce(
    (acc, item) => acc + (item && item.calls ? item.calls : 0),
    0
  );

  const startingDate =
    callsData.length > 0
      ? `${Number(callsData[0]?.month)}/${Number(callsData[0]?.year)}`
      : "";

  const endingDate =
    callsData.length > 0
      ? `${callsData[callsData.length - 1]?.month}/${callsData[callsData.length - 1]?.year
      }`
      : "";
  const callsDetails = {
    totalCalls: totalCalls,
    dateRange: `${(startingDate)} - ${(endingDate)}`,
  };

  function sortByYearAndMonth(array) {
    return array.sort((a, b) => {
      // prima ordina per anno
      if (a.year < b.year) return -1;
      if (a.year > b.year) return 1;

      // poi ordina per mese
      if (a.month < b.month) return -1;
      if (a.month > b.month) return 1;

      return 0;
    });
  }

  function getNum(num) {
    return typeof num === 'number' && !isNaN(num) ? num : 0
  }
  function calcolaTotalBandWidth(bandwidthData) {
    var dataset = []
    var counter = 0
    var orderBandwidth = sortByYearAndMonth(bandwidthData)
    orderBandwidth.map((item) => {

      dataset.push({
        year: item.year,
        month: item.month,
        bandwidth: counter += getNum(item.bandwidth)
      });

    })
    // console.log("Dati ordinati", dataset)
    return [dataset, counter]
  }

  var [dataSet, totalBand] = calcolaTotalBandWidth(bandwidthData);

  const bandwidthDetails = {
    totalBandWidth: (totalBand / 1024).toFixed(2),
    dataRange: `${(startingDate)} - ${(endingDate)}`,
  }
  // console.log(dataSet)
  // console.log("Banda",bandwidthData);

  const lineChartData = {
    labels: generateMissingData(startDate, endDate, bandwidthData).map(
      (item) => `${Number(item && item.month) ? item.month : ""}/${Number(item && item.year) || ""}`
    ),
    datasets: [
      {
        label: "Monthly Bandwidth (GB)",
        data: generateMissingData(startDate, endDate, bandwidthData).map(
          (item) => {
            return (item && item.bandwidth ? item.bandwidth / 1024.0 : 0).toFixed(2);
          }
        ),
        borderColor: "#41b1ac",
        backgroundColor: "#41b1ac60",
        fill: true,
        tension: 0,
      },
      {
        label: "Total BandWidth (GB)",
        data: generateMissingData(startDate, endDate, dataSet).map((item) => {
          return (item && item.bandwidth ? item.bandwidth / 1024.0 : 0).toFixed(2);
        }),
        borderColor: '#ff2464',
        backgroundColor: '#ff246460',
        fill: true,
        tension: 0
      },
      // {
      //   label: "Free Limit Month",
      //   data: generateMissingData(startDate, endDate, bandwidthData).map(
      //     (item) => 25
      //   ),
      //   borderColor: "#000",
      //   fill: false,
      //   tension: 0,
      // },
    ],
  };


  // Update bar chart data
  const barChartData = {
    labels: generateMissingData(startDate, endDate, callsData).map(
      (item) => `${Number(item && item.month) ? item.month : ""}/${Number(item && item.year) || ""}`
    ),

    datasets: [
      {
        label: "Calls",
        data: generateMissingData(startDate, endDate, callsData).map(
          (item) => item?.calls
        ),
        backgroundColor: "#241F45",
      },
    ],
  };

  return { barChartData, lineChartData, bandwidthDetails, callsDetails }

};

export const getMeterSDKLicense = async ({
  startDate,
  endDate,
  bandwidthType,
  subscription,
}) => {

  const snapshot = await db.collection("API_Consumption").
    doc(subscription.arpd_meter_consumption).
    get();
  let data = snapshot.data() ?? [];
  const milliseconds = subscription.start_date.seconds * 1000 + Math.round(subscription.start_date.nanoseconds / 1000000);
  const subDate = new Date(milliseconds);


  // console.log("Load Meter", data)
  const callsData = [
    ...(bandwidthType === "ARPD_meter_app_sdk" || bandwidthType === "all"
      ? (data && data.ARPD_meter_app_sdk) ? data.ARPD_meter_app_sdk : []
      : []),
    ...(bandwidthType === "App_sdk" || bandwidthType === "all"
      ? (data && data.App_sdk) ? data.App_sdk : []
      : []),
    ...(bandwidthType === "ARPD_meter_web_sdk" || bandwidthType === "all"
      ? (data && data.ARPD_meter_web_sdk) ? data.ARPD_meter_web_sdk : []
      : []),
  ];

  callsData.flat()
    .filter((item) => {
      const year = Number(item && item.year);
      const month = Number(item && item.month);
      const date = new Date(year, month - 1, 1);
      return (
        (subDate <= date)
      );
    })
    .reduce((acc, item) => {
      const existingItem = acc.find(
        (accItem) =>
          Number(accItem.year) === Number(item?.year) &&
          Number(accItem.month) === Number(item?.month)
      );
      // console.log("ITEM", existingItem)
      if (existingItem) {
        existingItem.calls += item?.calls;

      } else {
        acc.push({
          year: Number(item?.year),
          month: Number(item?.month),
          calls: item && item.calls ? item.calls : 0,
          calls_number: item && item.calls ? item.calls : 0,
        });

      }
      // console.log("CALL DATA",acc)

      return acc;
    }, []);
  const totalCalls = callsData.reduce(
    (acc, item) => acc + (item && item.calls ? item.calls : 0),
    0
  );

  const startingDate =
    callsData.length > 0
      ? `${Number(callsData[0]?.month)}/${Number(callsData[0]?.year)}`
      : "";

  const endingDate =
    callsData.length > 0
      ? `${callsData[callsData.length - 1]?.month}/${callsData[callsData.length - 1]?.year
      }`
      : "";
  const callsDetails = {
    totalCalls: totalCalls,
    dateRange: `${(startingDate)} - ${(endingDate)}`,
  };


  const barChartData = {
    labels: generateMissingData(startDate, endDate, callsData).map(
      (item) => `${Number(item && item.month) ? item.month : ""}/${Number(item && item.year) || ""}`
    ),

    datasets: [
      {
        label: "Calls",
        data: generateMissingData(startDate, endDate, callsData).map(
          (item) => item?.calls
        ),
        backgroundColor: "#241F45",
      },
    ],
  };

  return { barChartData, callsDetails }
};


export const getStartDate = async ({
  listOfBrandId
}) => {
  try {
    let farthestStartDate = null;
    // console.log("LISTA BRAND", listOfBrandId);
    listOfBrandId.map(async (docID) => {
      const docRef = db.collection('Brand').doc(docID);
      // console.log()
      const docSnapshot = await docRef.get();

      if (docSnapshot.exists) {
        const brandData = docSnapshot.data();
        const brandStartDate = brandData.startDate;

        if (!farthestStartDate || brandStartDate > farthestStartDate) {
          farthestStartDate = brandStartDate;
        }
      }
    })

    // console.log("START DATE", farthestStartDate)
    return farthestStartDate
  } catch (error) {
    // console.log('Errore durante il recupero dei dati:', error);
  }
};

//fetches subsritons based on preference (is it branded licence or sdk licence)
export const fetchSubs = async (setSubscriptions, isBrandLicence = true) => {
  const accRef = localStorage.getItem('ref_cliente');

  const clientDoc = await db
    .collection("Client").doc(accRef).get();

  // Abbiamo letto i licenseList dal cliente
  const clientData = clientDoc.data();
  const licenseList = clientData.licenseList;
  let subscriptionList = [];
  if (localStorage.getItem("role") === "Admin") {
    const allDocument = await db.collection("ARShades_Subscription").where("isRefactored", "==", true).get();
    // console.log("All Doc", allDocument);

    subscriptionList = await Promise.all(
      allDocument.docs.map(async (docRef) => {
        try {
          const docSnapshot = await db.collection("ARShades_Subscription").doc(docRef.id).get(); // Ottieni lo snapshot del documento
          const docData = docSnapshot.data(); // Ottieni i dati del documento
          return docData; // Restituisci i dati del documento
        } catch (error) {
          console.error('Errore nel recuperare il documento:', error);
          throw error; // Lancia l'errore per gestirlo più avanti, se necessario
        }
      })
    )
  } else {
    subscriptionList = await Promise.all(
      licenseList.map(async (docRef) => {
        const doc = await db.collection("ARShades_Subscription").doc(docRef).get();
        return doc.data();
      })
    );
  }
  // Abbiamo letto tutte le prox prietà del documento licenseList all'interno di ARShades_Subscription

  // console.log("ALL Subscription List ", subscriptionList);

  // Ora leggiamo solo i licenseList con lo 'status' su 'Active', quindi la  query non ci serve
  // const activeSubscriptions = subscriptionList.filter(item => item.status === 'Active');
  // console.log("FILTERED Subscription List", activeSubscriptions);


  // ARShades Branded Licence 2 is ARShades Smart Licence ----- Consumption have same logic
  //3 ARShades SDK Licence ARPD Meter, ARPD Meter SDK
  //FOR THE FUTURE WE ARE READING ALL SUBSCRIPTIONS TYPE
  const subRef = await db
    .collection("ARShades_Subscription")
    .where('clientRef', '==', accRef)
    .where('status', '==', 'Active')
    .where('subscriptionType', 'in', isBrandLicence ? ['VTO Branded Licence', 'VTO Smart License', 'VTO Additional Branded License Domain', "VTO Additional Branded License Campaing"] : ['ARShades SDK Licence', "ARPD Meter SDK License"])
    .get();
  //check if the status properties is active
  const subs = [];
  subscriptionList.map((el) => {
    const brands = [];
    const sub = el;

    if(sub.subscriptionType === "VTO Additional Branded License Domain" || sub.subscriptionType === "VTO Additional Branded License Campaing" || sub.subscriptionType === "VTO Branded License" || sub.subscriptionType === "VTO Smart License"){
    //get brands from the brand reference list
    if (sub.catalogList.catalogRefList) {
      sub.catalogList.catalogRefList.map(async (brandRef) => {
        if (brandRef) {
          const brand = await db.collection("Brand").doc(brandRef).get();
          brands.push(brand.data());
        }
      })
    }
    sub.brands = brands;
    subs.push(sub);
  }
  })
  // console.log("Subscription Fetchate", subs);
  setSubscriptions(subs);
  return subs;
}

function getDataConsumptionUnitValue(valueInMb, month, year) {
  // Get the number of days in the specified month
  const daysInMonth = new Date(year, month, 0).getDate();

  // Convert value from MB to GB
  const valueInGb = valueInMb / 1024;

  // Multiply by the number of days in the month
  const result = valueInGb * daysInMonth;

  // Divide by 5
  const finalResult = (result / 5) * 1024;

  return finalResult;
}