// URL STAGING
export const BASE_URL_STAGING = "https://stagingdashboard.arshades.it";

// URL SPAARKLY
export const BASE_URL_SPAARKLY = "https://arshades.spaarkly.it";

// URL STUDIO
export const BASE_URL_STUDIO = "https://studio.arshades.it";

// URL STAGING
export const BASE_URL_MODELERSTAGING = "https://stagingmodelerdashborad.arshades.it";

// URL SPAARKLY
// export const BASE_URL_MODELERPROD = "https://dashboard.arshades.it";
export const BASE_URL_MODELERPROD = "https://arshades-7e18a.web.app";

////// ANDARE SU signin.js nella funzione 'createCustomToken' //////

// Verify Token Produzione
// https://verifyandcreatetoken-xcule5rnvq-uc.a.run.app
export const BASE_URL_TOKENPROD = "https://verifyandcreatetoken-xcule5rnvq-uc.a.run.app";

// Verify Token Staging
// https://verifyandcreatetoken-4drmnlkoeq-uc.a.run.app
export const BASE_URL_TOKENSTAGING = "https://verifyandcreatetoken-4drmnlkoeq-uc.a.run.app";

//////

/// URL PRINCIPALE ///
export const ACTIVE_BASE_URL = BASE_URL_STUDIO;
export const ACTIVE_BASE_URL_DASH = BASE_URL_MODELERPROD;
export const ACTIVE_BASE_TOKEN = BASE_URL_TOKENPROD;
/////////////////////